import { useSelector }           from 'react-redux'
import CommunityForumAskQuestion from '../components/Forum/CommunityForumAskQuestion'
import Hero                      from '../components/Hero/Hero'
import IFrame                    from '../components/IFrame/IFrame'
import LatestNews                from '../components/LatestNews/LatestNews'
import LatestProducts            from '../components/LatestProducts/LatestProducts'
import SearchBar                 from '../components/Search/SearchBar'
import StatsLinks                from '../components/StatsLinks/StatsLinks'
import MainLayout                from '../layouts/MainLayout'

export default function Home() {
  const currentTenant = useSelector( state => state.currentTenant.tenant )

  return (
    <MainLayout>
      <div className="max-w-screen-3xl mx-auto w-full">
        <div className="flex-grow lg:p-10 p-3 sm:space-y-3 space-y-1.5">

          <section className="flex md:flex-row items-start flex-col md:justify-between gap-x-12">
            <header className="">
              <p className="sm:text-base text-sm font-medium text-gray-600">Dashboard</p>
              <h1 className="font-bold sm:text-2xl text-lg sm:mb-2 mb-1 whitespace-nowrap">Banana Moon Hub</h1>
            </header>

            <SearchBar
              placeholder="Search"
              type="all"
            />
          </section>

          <StatsLinks />

          <section className="flex gap-4">
            <div className="flex-1 space-y-6">
              <div className="flex flex-col gap-4">
                <CommunityForumAskQuestion />

                <Hero
                  title="The Midnight Moon Awards 2025"
                  description="Early bird tickets are now on sale"
                  link={  '/shop/midnight-moon-awards' }
                  imageUrl={ `${ process.env.REACT_APP_CDN }/assets/MMA_Banner.jpg` }
                  ctaTitle="Buy tickets"
                />
              </div>

              <LatestNews />
            </div>

            <aside className="max-w-lg w-full lg:block hidden space-y-4 w-96">
                                <>
                                    { currentTenant?.dashboards?.map( data => (
                                      <IFrame
                                        key={ data.url }
                                        title={ data.name }
                                        reportUrl={ data.url }
                                      />
                                    ) ) }
                                </>
              <LatestProducts />
            </aside>
          </section>
        </div>
      </div>
    </MainLayout>
  )
}
